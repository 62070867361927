import React, { useEffect, useRef, useState } from "react";
import { CONFIGURATIONS } from "./constants";
import Dialog from "./dialog";
import PropTypes from "prop-types";
import Auth from "../../api/ApiSession";

const authCall = new Auth();

const styles = {
  startStopStyles: {
    margin: "4px 0 4px 1px",
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "2%",
  },
  selectStyles: {
    minWidth: "100%",
    borderRadius: "3px",
    maxHeight: "34px",
    lineHeight: "16px",
    fontSize: "16px",
    fontWeight: "500",
    padding: "6px 32px 6px 8px",
  },
  buttonStyles: {
    minWidth: "35%",
    marginLeft: "2%",
  },
  redBtnEnable: {
    color: "red"
  },
  redBtnDisable: {
    color: "red",
    visibility: "collapse"
  },
  height: {
    height: "25px"
  }
};

const StartStop = ({ deviceData, setDeviceData }) => {  
  const [deviceSettings, setDeviceSettings] = useState({});
  const [isMotorMsg, setMotorMsg] = useState(false);
  const [snackBarObj, setSnackBarObj] = React.useState({});
  const [remoteControl, setRemoteControl] = useState(false);
  const [isRemoteModal, setIsRemoteModal] = useState(false);

  useEffect(() => {
    fetchDeviceData(deviceData.DeviceId); 
    getTransaction();    
  }, []);  

  const fetchDeviceData = (DeviceId) => {
    authCall
      .getConfigurationData(CONFIGURATIONS.GetData, {
        DeviceId,
      })
      .then((response) => {
        if(response[0].Status === "Remote"){
          setRemoteControl(response[0].RemoteStartStop === 1 ? true : false);
        }else{
          setRemoteControl(false);
        }
        
        setDeviceSettings({...response[0],DeviceId});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFrequencyChange = ({ target: { value } }) => {  
    const result = value.replace(/\D/g, '');
    if (value.length > 3) {
      return;
    }    
    setDeviceData({
      ...deviceData,
      Frequency: result,
    });   
  };  

  const getTransaction = () => {
    authCall.getTransactionData(CONFIGURATIONS.GetTransaction,sessionStorage.getItem("customerId"), 'InProgress',sessionStorage.getItem("userId")).then(json => {      
      if (json.length>0) {        
        var motorArray = json.filter(function(cmd){ return cmd.commandName === 'StartOrStop'; });
        
        if(motorArray.length >0){
          setMotorMsg(true);
        }
      }
    })
  };

  const handleConfirm = () => {
    setIsRemoteModal(false);
    authCall
      .putUpdateStartStop(CONFIGURATIONS.Reset, {
        DeviceId: deviceData.DeviceId,
        TopicName: remoteControl ? "Start" : "Stop",
        ResetCommand: remoteControl ? "Start" : "Stop",
        ResetType: "True",
        userId: sessionStorage.getItem("userId")
      })
      .then((response) => {
        alert();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const alert = () => {
    document.getElementById('remote-snackbar').open = true;
    setSnackBarObj({ type: "success", msg: "Command Initiated successfully." });
  };

  const handleModalClose = () => {
    setIsRemoteModal(false);
    setRemoteControl(!remoteControl);
  };

  const Clicked = (event) => {
    if(!event.target.disabled)
    {
      setRemoteControl(!remoteControl);
      setIsRemoteModal(!isRemoteModal);
    }
  }
  return (
    <se-container position="grid" direction="row" margin="large" padding="large">
      <se-block-content>
        
        <se-form-field
          id="input-stacked-form"
          type="input"
          option="stacked"
          label="Status"
          disabled
        >
          <div className="d-flex align-items-center">
            <input
              type="text"
              value={deviceSettings.Status}
              style={styles.selectStyles}
              name="input"
            />
          </div>
        </se-form-field>
        <p style={styles.startStopStyles}>{CONFIGURATIONS.StartStop}</p>
        <div className="d-flex">
          <p className="mr-3">Off</p>
          <se-checkbox
          style={styles.height}
            checked={deviceSettings.Status === "Remote" ? true : false}
            disabled={deviceSettings.Status === "Remote" ? false : true}
            id="toggle"
            option="switch"
            label-pos="right"
            selected={remoteControl}
            onClick={Clicked}
          ></se-checkbox>
          <p className="ml-3">On</p>
        </div>
        <p style={isMotorMsg==true? styles.redBtnEnable : styles.redBtnDisable}>{CONFIGURATIONS.Motor_Inprogress_Msg}</p>

        <div class="paddingLeft300" align="left">
          <se-snackbar id="remote-snackbar" duration="5000" type={snackBarObj.type}
            message={snackBarObj.msg}>            
          </se-snackbar>
        </div>
      </se-block-content>
      {isRemoteModal && (
        <Dialog
          open={isRemoteModal}
          handleClose={handleModalClose}
          handleConfirm={handleConfirm}
          dialoghead={CONFIGURATIONS.Dialog_Confirm}
          dialogContent={
            remoteControl
              ? CONFIGURATIONS.Dialog_Start_Content
              : CONFIGURATIONS.Dialog_Stop_Content
          }
        />
      )}
    </se-container >
  );
};
export default StartStop;

StartStop.propTypes = {
  slider: PropTypes.string,
};
