import React from 'react';
import mechine from '../../images/agroAsset2.png'
import asset from '../../images/asset.png';
import useMycustomerForm from '../myCustomersComponents/useMycustomerForm';
const _ = require('lodash');
const AddAssets = (props) => {
    let optionArray = ["Sugarcane Thresher", "Pump", "Rice Huller", "Paddy Thresher", "Others"]
    let formArray = [{
        PropId: "",PropStatus: true, PropData: [{
            PropName: "Asset Type",
            PropValue: sessionStorage.getItem("customerType") === "Agro" ? "Sugarcane Thresher" : "Pump",
            units: "", PropData: null
        },
        { PropName: "Title", PropValue: "", units: "", PropData: null },
        { PropName: "Motor HP", PropValue: "", units: "", PropData: null },
        { PropName: "Phase", PropValue: "", units: "", PropData: null },
        { PropName: "Voltage", PropValue: "", units: "V", PropData: null },
        { PropName: "Max Current", PropValue: "", units: "A", PropData: null },
        { PropName: "Manufacturer", PropValue: "", units: "", PropData: null },
        { PropName: "Drive HP", PropValue: "", units: "", PropData: null },
        { PropName: "Capacity Produced/hr", PropValue: "", units: "kg", PropData: null },
        { PropName: "Tariff/hr", PropValue: "", units: "", PropData: null },
        { PropName: "PV", PropValue: "", units: "kw", PropData: null },
        ]
    }]
    if(sessionStorage.getItem("dropdownCustomerType") === "SWP"){
        optionArray = _.filter(optionArray, function(ele) { return ele==="Pump" });
        formArray = [{
            PropId: "",PropStatus: true, PropData: [{
                PropName: "Asset Type",
                PropValue: sessionStorage.getItem("customerType") === "Agro" ? "Sugarcane Thresher" : "Pump",
                units: "", PropData: null
            },
            { PropName: "Title", PropValue: "", units: "", PropData: null },
            { PropName: "Motor Power", PropValue: "1", units: "kW", PropData: null },
            { PropName: "Motor HP", PropValue: "", units: "", PropData: null },
            { PropName: "Phase", PropValue: "", units: "", PropData: null },
            { PropName: "Voltage", PropValue: "", units: "V AC", PropData: null },
            { PropName: "Max Current", PropValue: "", units: "A", PropData: null },
            { PropName: "Manufacturer", PropValue: "", units: "", PropData: null },
            { PropName: "Frequency", PropValue: "50", units: "Hz", PropData: null },
            { PropName: "Motor Speed", PropValue: "", units: "RPM", PropData: null },
            { PropName: "Water discharge/hr", PropValue: "1", units: "L", PropData: null },
            { PropName: "Drive HP", PropValue: "", units: "", PropData: null },
            { PropName: "Capacity Produced/hr", PropValue: "", units: "", PropData: null },
            { PropName: "Tariff/hr", PropValue: "", units: "", PropData: null },
            { PropName: "PV", PropValue: "", units: "kw", PropData: null },
            ]
        }]
    }
    const [formData, setFormData] = React.useState(formArray)
    const [validateData, setvalidateData] = React.useState({})
    const { errors } = useMycustomerForm(formData, props.index, 
        (sessionStorage.getItem("dropdownCustomerType") === "SWP")?"SWP":"Agro")
    const [pVsqData, setPVsqData] = React.useState([])
    let isPVsQValid1 = false
    let isPVsQValid2 = false
    const onClosePopup = () => {
        let copyFormData = formArray
        props.close("dialog-AgroAsset-Popup")
        setFormData(copyFormData)
    }
    const onIncreament = () => {
        let copyFormData = JSON.parse(JSON.stringify(formData))
        if (formData[formData.length - 1].PropId === "") {
            formData[formData.length - 1].PropId = 1
        }
        formArray[0].PropId = (Number(formData[formData.length - 1].PropId) + 1).toString()
        copyFormData.push(formArray[0])
        setFormData(copyFormData)
        if (sessionStorage.getItem("customerType") === "SWP") {
            let indexCount = 0
            _.map(copyFormData, (ele, index) => {
                indexCount = index
            })
            onAssestsValueChange("Pump", formArray[0], "Asset Type", indexCount)
        }

    }
    const onDecreament = (index) => {
        if (formData.length > 1 && index > 0) {
            formData.splice(index, 1)
            let copyFormData = JSON.parse(JSON.stringify(formData))
            setFormData(copyFormData)
        }
    }
    const onAssestsValueChange = (value, data, PropName, formIndex) => {
        _.map(data.PropData, PropData => {
            if (PropData && _.find(data.PropData, ["PropName", "Type"]) === undefined) {
                if (PropData.PropName === "Asset Type" && value === "Pump") {
                    data.PropData.splice(8, 0, { PropName: "Type", PropValue: "Surface", units: "", PropData: null })
                    data.PropData.splice(10, 0, { PropName: "Enter Power Vs Water flow data", PropValue: "", units: "", PropData: { head: "", pvq: [{ Power: "", Discharge: "" }] } })
                    if(sessionStorage.getItem("dropdownCustomerType") !== "SWP"){
                        data.PropData.splice(13, 0, { PropName: "Motor Power", PropValue: "0.75", units: "kW", PropData: null })
                        data.PropData.splice(14, 0, { PropName: "Frequency", PropValue: "50", units: "Hz", PropData: null })
                        data.PropData.splice(15, 0, { PropName: "Motor Speed", PropValue: "", units: "RPM", PropData: null })
                        data.PropData.splice(16, 0, { PropName: "Water Discharge/hr", PropValue: "1", units: "litres", PropData: null })
                    }
                }
            } else if (value !== "Pump") {
                _.map(data.PropData, (PropData, index) => {
                    if (PropData && PropData.PropName === "Type") {
                        data.PropData.splice(index, 1)
                    }
                    if (PropData && PropData.PropName === "Enter Power Vs Water flow data") {
                        data.PropData.splice(index, 1)
                    }
                })
            }
            if (PropData && _.find(data.PropData, ["PropName", "Asset Name"]) === undefined) {
                if (PropData.PropName === "Asset Type" && value === "Others") {
                    data.PropData.splice(2, 0, { PropName: "Asset Name", PropValue: "", units: "", PropData: null })
                }
            } else if (value !== "Others") {
                _.map(data.PropData, (PropData, index) => {
                    if (PropData && PropData.PropName === "Asset Name") {
                        data.PropData.splice(index, 1)
                    }
                })
            }
        })
        _.find(data.PropData, ["PropName", PropName]).PropValue = value
        let copyFormData = JSON.parse(JSON.stringify(formData))
        copyFormData[formIndex] = data
        setFormData(copyFormData);
    }
    const openPopUp = (e, index) => {
        e.preventDefault()
        document.getElementById("power-vs-discharge" + index).open = true;
    }
    const onValueChange = (e, data, PropName, formIndex) => {
        let copyFormData = JSON.parse(JSON.stringify(formData))
        copyFormData[formIndex] = data
        setFormData(copyFormData);
        _.find(data.PropData, ["PropName", PropName]).PropValue = e.target.value
    }
    const addMore = (index) => {
        _.map(formData[index].PropData, PropData => {
            if (PropData.PropName === "Enter Power Vs Water flow data") {
                PropData.PropData.pvq.push({ Power: "", Discharge: "" })
            }
        })
        let copyFormData = JSON.parse(JSON.stringify(formData))
        setFormData(copyFormData)
    }
    const deleteTableRow = (index, innerIndex) => {
        _.map(formData[index].PropData, PropData => {
            if (PropData.PropName === "Enter Power Vs Water flow data") {
                PropData.PropData.pvq.splice(innerIndex, 1)
            }
        })
        let copyFormData = JSON.parse(JSON.stringify(formData))
        setFormData(copyFormData)
    }
    const onTableValuePowerChange = (e, index, innerindex) => {
        _.map(formData[index].PropData, PropData => {
            if (PropData.PropName === "Enter Power Vs Water flow data") {
                PropData.PropData.pvq[innerindex].Power = e.target.value
            }
        })
        let copyFormData = JSON.parse(JSON.stringify(formData))
        setFormData(copyFormData)
    }
    const onTableValueDischargeChange = (e, index, innerindex) => {
        _.map(formData[index].PropData, PropData => {
            if (PropData.PropName === "Enter Power Vs Water flow data") {
                PropData.PropData.pvq[innerindex].Discharge = e.target.value
            }
        })
        let copyFormData = JSON.parse(JSON.stringify(formData))
        setFormData(copyFormData)
    }
    const onTableValueHeadChange = (e, index) => {
        _.map(formData[index].PropData, PropData => {
            if (PropData.PropName === "Enter Power Vs Water flow data") {
                PropData.PropData.head = e.target.value
            }
        })
        let copyFormData = JSON.parse(JSON.stringify(formData))
        setFormData(copyFormData)
    }
    const onTableValuekfactorChange = (e, index) => {
        _.map(formData[index].PropData, PropData => {
            if (PropData.PropName === "Enter Power Vs Water flow data") {
                let error={};
                console.log(e.target.value)
                //if (!/^(-1|1|0|0?\.[0-9]{1,2}|-0?\.[0-9]{1,2})$/i.test(e.target.value)) {
                if(!/^(2|1|0|[0-1]\.[0-9]{1,2})$/i.test(e.target.value)) {
                    error.PropClass = "error-border"
                    error.PropMsg = "Only numbers are allowed(0 to 2) with 2 decimals"
                    isPVsQValid2=false
                } else {
                    error.PropClass = "valid-border"
                    error.PropMsg = ""
                    isPVsQValid2=true
                }
                PropData.PropData.kfactor = e.target.value
                console.log(error)
                setvalidateData(error)
            }
           
        })
        let copyFormData = JSON.parse(JSON.stringify(formData))
        setFormData(copyFormData)
    }
    const tableSave = (index) => {

        document.getElementById("power-vs-discharge" + index).open = false;
    }
    const renderError = (data, index) => {
        return _.map(errors[index].PropData, PropData => {
            if (PropData.PropName === data.PropName && PropData.PropMsg) {
                return <div className="se-error-text">
                    {/* <se-icon style={{ position: "relative", top: '4px' }} size="small" class="pointer">notification_error_plain</se-icon> */}
                    {PropData.PropMsg}</div>
            }
        })

    }
    const renderErrorForPVsQ = (data) => {
        return _.map(errors, ele => {
            if (ele.PropId === data.PropId) {
                return _.map(ele.PropData, innerEle => {
                    if (innerEle.PropName === "Enter Power Vs Water flow data") {
                        if (innerEle.PropMsg) {
                            isPVsQValid1 = true
                            isPVsQValid2 = true
                            return <div className="se-error-text">{innerEle.PropMsg}</div>
                        }
                        for (let i in innerEle.PropData.pvq) {
                            if (innerEle.PropData.pvq[0].Power === "" && innerEle.PropData.pvq[i].Discharge === "") {
                                isPVsQValid1 = false
                                isPVsQValid2 = false
                                break;
                            }
                            if (innerEle.PropData.pvq[0].Power !== "" && innerEle.PropData.pvq[i].Discharge === "") {
                                isPVsQValid1 = true
                                isPVsQValid2 = true
                                break;
                            }
                            if (innerEle.PropData.pvq[innerEle.PropData.pvq.length - 1].Power === "" && innerEle.PropData.pvq[innerEle.PropData.pvq.length - 1].Discharge === "") {
                                isPVsQValid1 = true
                                isPVsQValid2 = false
                                break;
                            }
                            if ((i !== "0") && (innerEle.PropData.pvq[i].Power === "" || innerEle.PropData.pvq[i].Discharge === "")) {
                                isPVsQValid1 = true
                                isPVsQValid2 = true
                                break;
                            }
                        }
                    }
                })
            }
        })
    }

    const renderClass = (data, index) => {
        let result = "se-form-control"
        _.map(errors[index].PropData, PropData => {
            if (PropData.PropName === data.PropName && PropData.PropMsg) {
                result = "se-form-control " + PropData.PropClass
            }
        })
        return result
    }
    const renderOption = () => {
        return _.map(optionArray, ele => {
            return <option value={ele}>{ele}</option>
        })
    }
    const renderFormList = _.map(formData, (data, index) => {
        return <div style={{ border: "1px solid lightgray", width: "100%", padding: "30px", position: 'relative' }}>
            {props.disabledIncreament ? null : <div style={{ position: 'absolute', right: "10px", top: "8px", zIndex: '1' }}>
                <se-icon size="small" class="pointer" onClick={() => onDecreament(index)}>action_delete_cross</se-icon></div>}
            {
                _.map(data.PropData, PropData => {
                    if (PropData.PropName === "Asset Type") {
                        return <div className="col-md-6" style={{ display: "inline-block" }}>
                            <div class="form-group row">
                                <label for="email_address" class="col-md-4 se-form-label text-md-right">{PropData.PropName}
                                    {PropData.units ? <span className="font-size-10">&nbsp;({PropData.units})</span> : null}
                                    <span class="se-mandatory">*</span></label>
                                <div class="col-md-8">
                                    <select disabled={props.infoData ? props.infoData.disabledAssetType : false} style={{ width: "100%" }} className="se-form-control" value={PropData.PropValue}
                                        onChange={(e) => onAssestsValueChange(e.target.value, data, PropData.PropName, index)}>
                                        {renderOption()}
                                    </select>
                                    {renderError(PropData, index)}
                                </div>
                            </div>
                        </div>
                    } else if (PropData.PropName === "Type") {
                        return <div className="col-md-6" style={{ display: "inline-block" }}>
                            <div class="form-group row">
                                <label for="email_address" class="col-md-4 se-form-label text-md-right">{PropData.PropName}
                                    {PropData.units ? <span className="font-size-10">&nbsp;({PropData.units})</span> : null}
                                    <span class="se-mandatory">*</span></label>
                                <div class="col-md-8">
                                    <select style={{ width: "100%" }} className="se-form-control" value={PropData.PropValue}
                                        onChange={(e) => onValueChange(e, data, PropData.PropName, index)}>

                                        <option value="Surface">Surface</option>
                                        <option value="Submersible">Submersible</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                    else if (PropData.PropName === "Enter Power Vs Water flow data") {
                        let id = "power-vs-discharge" + index
                        return <div className="col-md-6" style={{ display: "inline-block" }}>
                            <div class="form-group row">
                                <div className="col-md-4"></div>
                                <div class="col-md-8">
                                    <a href="" onClick={(e) => openPopUp(e, index)}>{PropData.PropName}</a>
                                </div>
                            </div>
                            <se-dialog id={id} color="alternative" can-backdrop="false" size="medium">
                                <se-container display="flex" padding="large" style={{height:"400px",overflow:"scroll",overflowX:"hidden"}}>
                                    <div className="assestTable" style={{ width: "100%", marginTop: "30px" }}>
                                    <div className="col-md-12" style={{fontSize:"16px",fontWeight:"600"}}>Power Vs Water flow data</div>
                                        <div className="row">
                                            <div className="col-md-6"> 
                                        <label class="form-label"><strong>Head(m)</strong></label>
                                        <input type="text" style={{ width: "250px", border: "1px solid lightgray", height: "30px" }}
                                            value={PropData.PropData.head} class="upload-file" onChange={(e) => onTableValueHeadChange(e, index)} />
                                        </div>
                                        <div className="col-md-6"> 
                                         <label class="form-label"><strong>K-Factor</strong></label>
                                        <input type="text" style={{ width: "250px", border: "1px solid lightgray", height: "30px" }}
                                            value={PropData.PropData.kfactor} className={validateData.PropClass} onChange={(e) => onTableValuekfactorChange(e, index)} />
                                            {validateData.PropMsg!==""?<div className="se-error-text">{validateData.PropMsg}</div> :null}
                                        </div>
                                        </div>
                                        <br></br>
                                        <se-table>
                                            <se-table-group-header>
                                                <se-table-item-header flex="1"><strong>Power</strong>
                                                    <span className="font-size-12">(W)</span>
                                                </se-table-item-header>
                                                <se-table-item-header flex="2"><strong>Discharge</strong>
                                                    <span className="font-size-12">(L/min)</span>
                                                </se-table-item-header>
                                                {/* <se-table-item-header flex="1"><strong>Head</strong>
                                                    <span className="font-size-12">(m)</span>
                                                </se-table-item-header> */}
                                                <se-table-item-header flex="1"><strong>Action</strong>
                                                </se-table-item-header>
                                            </se-table-group-header>
                                            {_.map(PropData.PropData.pvq, (ele, innerIndex) => {
                                                return <se-table-group>
                                                    <se-table-item flex="1">
                                                        <input className="assestsInput" onChange={(e) => onTableValuePowerChange(e, index, innerIndex)} value={ele.Power} />

                                                    </se-table-item>
                                                    <se-table-item flex="2">
                                                        <input className="assestsInput" onChange={(e) => onTableValueDischargeChange(e, index, innerIndex)} value={ele.Discharge} />
                                                    </se-table-item>
                                                    {/* <se-table-item flex="1">
                                                        <input className="assestsInput" onChange={(e) => onTableValueHeadChange(e, index, innerIndex)} value={ele.Head} />
                                                    </se-table-item> */}
                                                    <se-table-item flex="1">
                                                        <se-icon style={{ cursor: "pointer", position: "absolute", top: "2px" }} onClick={() => deleteTableRow(index, innerIndex)} class="pr-2" size="small" color="standard">action_delete</se-icon>
                                                    </se-table-item>
                                                </se-table-group>
                                            })}

                                        </se-table>
                                        {renderErrorForPVsQ(data)}
                                        <se-button disabled={isPVsQValid1} onClick={() => addMore(index)}>Add</se-button>
                                    </div>
                                </se-container>
                                <se-dialog-footer>
                                    <se-button disabled={isPVsQValid2} onClick={() => tableSave(index)}>ok</se-button>
                                </se-dialog-footer>
                            </se-dialog>
                        </div>
                    }
                    else {
                        if (PropData.PropName !== 'Load') {
                            return <div className="col-md-6" style={{ display: "inline-block" }}>
                                <div class="form-group row">
                                    <label for="email_address" class="col-md-4 se-form-label text-md-right">{PropData.PropName}
                                        {PropData.units ? <span className="font-size-10">&nbsp;({PropData.units})</span> : null}
                                        {/* {PropData.PropName ==="Motor Power" || PropData.PropName ==="Frequency" || PropData.PropName ==="Water Discharge/hr " || PropData.PropName.trim() ==="Water Discharge/hr"?null:<span class="se-mandatory">*</span>}</label> */}                                        
                                        {PropData.PropName ==="Motor Power" || PropData.PropName ==="Frequency" || PropData.PropName ==="Water Discharge/hr "?null:<span class="se-mandatory">*</span>}</label>
                                    <div class="col-md-8">
                                        <input type="text" style={{ width: "100%" }}
                                            onChange={(e) => onValueChange(e, data, PropData.PropName, index)} value={PropData.PropValue}
                                            className={renderClass(PropData, index)} autoComplete="off" />
                                        {renderError(PropData, index)}
                                    </div>
                                </div>
                            </div>
                        }
                    }
                })
            }
        </div>
    })
    props.onChange(formData, errors)
    React.useEffect(() => {
        if (props.infoData) {
            if (props.infoData.disabledAssetType) {
                let infoData = JSON.parse(JSON.stringify(props.infoData))
                infoData.data.PropData.unshift({
                    PropName: "Asset Type", PropValue: props.infoData.assetsType,
                    units: "", PropData: null
                })
                infoData.data.PropData = _.uniqBy(infoData.data.PropData, 'PropName')
                setFormData(infoData.data)
                onAssestsValueChange(props.infoData.assetsType, infoData.data, "Asset Type", 0)
            } else {
                setFormData(formArray)
                if (sessionStorage.getItem("customerType") === "SWP") {
                    onAssestsValueChange("Pump", formArray[0], "Asset Type", 0)
                }
            }
        } else {
            setFormData(formArray)
            if (sessionStorage.getItem("customerType") === "SWP") {
                onAssestsValueChange("Pump", formArray[0], "Asset Type", 0)
            }
        }
    }, [props.infoData])
    return <se-block display="grid">
        <div className="rows">
            {/* <div className="col-md-12">
                <div align="center"><img src={mechine} height="100px"></img></div>
                <br></br>
            </div> */}
            <div className="col-md-12">
                <div align="center"><img src={asset} height="70px"></img></div>
                <br></br>
                {props.disabledIncreament || sessionStorage.getItem("dropdownCustomerType")==="SWP" ? null : <div align="center">
                    {formData.length}
                    {formData.length === 4 ? null : <se-icon size="small" onClick={() => onIncreament()} class="pointer align-middle">electricity_polarity_positive_plus</se-icon>}

                </div>}
            </div>
            {renderFormList}
            {props.disabledIcon ? null :
                <div style={{ position: "absolute", right: "20px", top: "0px" }}>
                    <se-icon size="small" class="pointer" onClick={() => onClosePopup()}>action_delete_cross</se-icon>
                </div>}
        </div>
    </se-block>
}
export default AddAssets