import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Auth from "../api/ApiSession";
import moment from "moment";
import AuthService from "../services/AuthService";
import UserManagment from "../pages/usermanagment";
var authService = new AuthService();
// var dataprivacyhtml= require('../doc/dataprivacy.html');
const authCall = new Auth();
class ShellLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navStatus: true,
      menuBar: true,
      headerStatus: true,
      lastUpdated: null,
      termsSubmit: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("terms") !== "1") {
      this.openPopUp("dialog-Popup");
    }
  }
  // fetchDashboardData() {
  //     let customerType = sessionStorage.getItem("customerType") ? sessionStorage.getItem("customerType") : "Agro"
  //     var jsonBody = {
  //         userId: parseInt(sessionStorage.getItem("userId")),
  //         siteName: sessionStorage.getItem("siteName"),
  //         customerId: parseInt(sessionStorage.getItem("customerId")),
  //         customerType: customerType
  //     };
  //     authCall.postCalculateWidget(jsonBody).then(json => {
  //         this.setState({ lastUpdated: json.OverWidgetDetails[0].lastUpdated })
  //     }).catch(error => {
  //         console.log(error);
  //     });
  // }
  // componentWillUnmount() {
  //     clearInterval(this.state.fetchDashboardDataInterval);
  // }
  onButtonLogout() {
    console.log("logout working");
    authService.logout();
    sessionStorage.clear();
    // let { history } = this.props;
    //         history.push({
    //             pathname: '/login'
    //         });
  }
  onMyProfile() {
    window.location.href = process.env.REACT_APP_PROFILE_UPDATE;
  }
  onPasswordChange() {
    window.location.href = process.env.REACT_APP_PASSWORD_CHANGE;
  }
  openPopUp(id) {
    // if (isDisabled === false) {
    document.getElementById(id).open = true;
    // }
    // let dataForaddAssets = {
    //   assetsType: null, data: null,
    //   disabledAssetType: false
    // }
    // setEditAssetsData(dataForaddAssets)
    // setSelectedTermialOnEdit(false)
  }
  onClosePopUp(callback) {
    // setEditAssetsData(null)
    localStorage.setItem("terms", 1);
    document.getElementById(callback).open = false;
  }
  onTermsClick(e) {
    this.state.termsSubmit = !e.target.selected;
  }
  getDate(data) {
    if (data) {
      return this.props.dateValue;
    } else {
      return moment().format("DD/MM/YYYY h:mm:ss a");
    }
  }
  gotoLanding() {
    // return
    let typ = sessionStorage.getItem("UserType");
    if (typ === "Admin" || typ === "User") {
      sessionStorage.setItem("customerType", typ);
      let { history } = this.props;
      history.push({
        pathname: "/Landing",
      });
    } else {
      let { history } = this.props;
      history.push({
        pathname: "/overview",
      });
    }
  }
  onUserManagmentClick() {
    console.log("trigger");
    let { history } = this.props;
    history.push({
      pathname: "/UserManagment",
    });
    console.log(history);
  }

  render() {
    let timeStamp = Math.floor(new Date().getTime() / 1000.0);
    let sessionTimeOut = parseInt(sessionStorage.getItem("loggedInTime"));
    
    if (sessionStorage.getItem("userId") === null) {
      let { history } = this.props;
      history.push({
        pathname: "/",
      });
    } else if (sessionStorage.getItem("UserType") === "null") {
      let { history } = this.props;
      history.push({
        pathname: "/unauthorized",
      });
    } else if (sessionTimeOut === 0) {
      timeStamp += 45 * 60;
      sessionStorage.setItem("loggedInTime", timeStamp);
    } else if (timeStamp < sessionTimeOut) {
      timeStamp += 45 * 60;
      sessionStorage.setItem("loggedInTime", timeStamp);
    } else {
      this.onButtonLogout();
    }
    if (
      this.props.history.location.pathname === "/createnewuser" ||
      this.props.history.location.pathname === "/createReport" ||
      this.props.history.location.pathname === "/CapacityUtilizationDetails"
    ) {
      this.state.headerStatus = false;
      this.state.menuBar = false;
      this.state.navStatus = false;
    } else if (this.props.history.location.pathname === "/signup") {
      this.state.navStatus = false;
    } else if (
      this.props.history.location.pathname === "/Landing" ||
      this.props.history.location.pathname === "/landing"
    ) {
      this.state.navStatus = true;
      this.state.headerStatus = true;
      this.state.menuBar = false;

      if (
        sessionStorage.getItem("customerType") === "Agro" ||
        sessionStorage.getItem("customerType") === "SWP"
      ) {
        let { history } = this.props;
        history.push({
          pathname: "/overview",
        });
      }
    } else {
      this.state.headerStatus = true;
      this.state.navStatus = true;
      this.state.menuBar = true;
    }

    const userName = sessionStorage.getItem("loginUserName");
    return (
      <se-container position="absolute" direction="column">
        {this.props.history.location.pathname === "/dataManagement" ? null : (
          <div
            className={
              this.state.headerStatus ? "blockmycustomerDiv" : "noneDiv"
            }
          >
            <se-header app-title="Energy Access Livelihood">
              {this.state.navStatus ? (
                <se-sidemenu id="app-sidemenu">
                  {sessionStorage.getItem("UserType") === "Admin" ? (
                    <se-sidemenu-item item="User Managment">
                      <UserManagment />
                    </se-sidemenu-item>
                  ) : null}

                  {/* <se-sidemenu-item item="Preferences"></se-sidemenu-item> */}
                  <se-sidemenu-item item="Terms & Conditions">
                    <se-block>
                      <div class="border-bottom-overview userProfileHeader1Height"></div>
                      <div class="border-bottom-overview userProfileHeader2Height d-flex align-items-center">
                        <se-block-header className="px-0">
                          <span className="font-normal font-weight-600 NotificationHeaderColor pt-2">
                            Terms & Conditions
                          </span>
                        </se-block-header>
                      </div>
                      <se-link
                        url="https://www.se.com/in/en/about-us/legal/terms-of-use.jsp"
                        option="external"
                      >
                        Terms of use
                      </se-link>
                      <se-link
                        url="https://www.se.com/in/en/about-us/legal/data-privacy.jsp"
                        option="external"
                      >
                        Data Privacy Statement
                      </se-link>
                    </se-block>
                  </se-sidemenu-item>
                  {/* <se-sidemenu-item item="Support"></se-sidemenu-item> */}
                </se-sidemenu>
              ) : null}
              <div
                slot="end"
                style={this.state.navStatus ? {} : { display: "none" }}
              >
                {this.props.history.location.pathname === "/Landing" ? null : (
                  <span>
                    <se-icon
                      option="inherit"
                      color="primary"
                      class="pointer"
                      onClick={this.gotoLanding.bind(this)}
                    >
                      home
                    </se-icon>
                    <span
                      style={{ fontSize: "15px", verticalAlign: "text-bottom" }}
                    >
                      {sessionStorage.getItem("customerType") === "Agro"
                        ? "Villaya Agri"
                        : sessionStorage.getItem("customerType") === "SWP"
                        ? "Villaya Solar Water Pumping"
                        : ""}
                    </span>
                  </span>
                )}
                {/* <se-button slot="trigger" option="inherit" icon="home" icon-color="primary" onClick={this.gotoLanding.bind(this)}>{sessionStorage.getItem("customerType") === "Agro" ? "Villaya Agri" : sessionStorage.getItem("customerType") === "SWP" ? "Villaya Solar Water Pumping" : ''}</se-button> */}
                <se-dropdown alignment="right">
                  <se-button
                    slot="trigger"
                    option="inherit"
                    icon="user_standard"
                    icon-color="primary"
                  >
                    {userName}
                  </se-button>
                  <se-list option="dropdown">
                    <se-list-item
                      onClick={this.onMyProfile.bind(this)}
                      item="My Profile"
                    ></se-list-item>
                    <se-list-item
                      onClick={this.onPasswordChange.bind(this)}
                      item="Change Password"
                    ></se-list-item>
                    <se-divider></se-divider>
                    <se-list-item
                      onClick={this.onButtonLogout.bind(this)}
                      item="Logout"
                    ></se-list-item>
                  </se-list>
                </se-dropdown>
              </div>
              {/* <div slot="end">
                            <se-button option="inherit" icon="headset" icon-color="secondary">
                                <se-link  color="secondary" option="internal">Contact Support</se-link>
                            </se-button>
                        </div> */}
            </se-header>
          </div>
        )}
        {this.props.history.location.pathname === "/dataManagement" ? null : (
          <se-tabbar color="primary">
            <nav style={this.state.menuBar ? {} : { display: "none" }}>
              <NavLink to="/overview" activeClassName="active">
                Overview
              </NavLink>
              <NavLink to="/notifications">Notifications</NavLink>
              {/* <NavLink to='/localMap' >Map</NavLink> */}
              <NavLink to="/mycustomers">Customers</NavLink>
 
              {//sessionStorage.getItem("UserType") === "Admin" ? (
              <NavLink to="/configuration" id="configPage">Configuration</NavLink>
              //) : null
              }
              <NavLink to="/reports">Reports</NavLink>
              {/* //Admin */}
              {sessionStorage.getItem("UserType") === "Admin" ? (
                <NavLink to="/admin">Admin</NavLink>
              ) : null}
              {/* <NavLink to='/admin' >Admin</NavLink>  */}
              <NavLink to="/gallery">Gallery</NavLink>
            </nav>
            <nav
              style={
                this.state.menuBar
                  ? {
                      "padding-right": "20px",
                      "font-size": "12px",
                      "text-transform": "none",
                      height: "44px",
                      width: "290px",
                    }
                  : { display: "none" }
              }
              slot="end"
            >
              Last refresh :{" "}
              {this.getDate(true)
                ? this.getDate(true)
                : moment().format("DD/MM/YYYY h:mm:ss a")}
            </nav>
          </se-tabbar>
        )}
        {this.props.children}

        <se-dialog
          id="dialog-Popup"
          color="alternative"
          can-backdrop="false"
          size="large"
        >
          <se-dialog-header>Livelihood Privacy Notice</se-dialog-header>
          <se-container display="flex" padding="large">
            <div
              class="modal-body font-size-14"
              style={{ fontSize: "14px !importent" }}
            >
              <div class="container mt-2 mb-4 term-height ">
                <p class="text-justify font-size-18 font-weight-bold">
                  We commit to serve you, our customers, with care,
                  consideration, and respect and to protect your privacy and
                  personal information.
                </p>
                <p class="text-justify">
                  Our Data Privacy Policy explains how and why we use personal
                  information. This supplemental notice provides additional
                  information about Livelihood
                </p>
                <ol class="text-justify">
                  <li>
                    <p class="font-size-16" style={{ color: "#3DCD58" }}>
                      <strong>Who is in charge of data processing?</strong>
                    </p>
                    <p>
                      The processing of personal information is carried out by
                      Schneider Electric Industries SAS (“we”) as data
                      controller.{" "}
                    </p>
                  </li>

                  <li>
                    <p class="font-size-16" style={{ color: "#3DCD58" }}>
                      <strong>
                        What types of personal information do we process?{" "}
                      </strong>
                    </p>
                    <ul>
                      <li>
                        <p>Contact information</p>
                      </li>
                      <li>
                        <p>
                          Transaction and interaction information including
                          account information and related records, professional
                          information (company & function)
                        </p>
                      </li>
                      <li>
                        <p>
                          Online & technical information (information about
                          devices you use, such as their identifiers, model and
                          version).
                        </p>
                      </li>
                      <li>
                        <p>
                          Like any system, connections are logged for security
                          purposes
                        </p>
                      </li>
                    </ul>
                    <p>
                      In cases where your account is managed by someone else
                      (such as an administrator or employer), they may provide
                      data on your behalf.
                    </p>
                    <p>
                      We use information for the purpose of delivering our
                      services, interacting with users, managing customer
                      relationships, ensuring security and compliance with
                      contract and terms of use, and analyzing use of services
                      to improve them. This is based on [our legitimate interest
                      to operate the platform and to provide services to our
                      customers.]{" "}
                    </p>
                    <p>
                      We may collect and use other types of personal information
                      as described in our Data Privacy Policy. Depending on the
                      circumstances, these activities may be based on the
                      execution of a contract with you, on our legitimate
                      interest, on the legitimate interest of a third party, or
                      for compliance with a legal obligation.
                    </p>
                    <p>
                      We do not make decisions based solely on automated
                      processing which may produce legal effects or similarly
                      affect you.
                    </p>
                  </li>

                  <li>
                    <p class="font-size-16" style={{ color: "#3DCD58" }}>
                      <strong>
                        How long do we keep your personal information?
                      </strong>
                    </p>
                    <p>
                      We keep information as needed to perform the purposes
                      described above, taking into consideration the need to
                      provide the services, marketing requirements, security
                      requirements, legal requirements and statute of
                      limitations.{" "}
                    </p>
                  </li>

                  <li>
                    <p class="font-size-16" style={{ color: "#3DCD58" }}>
                      <strong>
                        Who do we share personal information with and where is
                        it processed?
                      </strong>
                    </p>
                    <p>
                      Personal information will be processed by our affiliates
                      and suppliers involved in the provision of the services
                      and in the performance of the purposes described in
                      Section 2. We take measures to ensure that personal
                      information receives an adequate level of protection. We
                      do not sell your personal information.
                    </p>
                    <p>
                      Our affiliates and suppliers provide service including
                      hosting and maintenance, performance monitoring and
                      security. As we are a global company, teams and suppliers
                      may have global or multi-country roles, and they can be
                      located anywhere in the world, in countries with different
                      privacy standards than the country of our customers.{" "}
                    </p>
                    <p>
                      We have internal policies applied by our affiliates and we
                      conclude contracts with our suppliers to ensure
                      appropriate safeguards. Our group has adopted Binding
                      Corporate Rules (BCR). In addition, we conclude Standard
                      Contractual Clauses and rely on EU Commission’s adequacy
                      decisions. To obtain more details and copies of safeguards
                      put into place, you may contact
                      global-data-privacy@schneider-electric.com.
                    </p>
                    <p>We may also share your information, as necessary:</p>
                    <ul>
                      <li>
                        <p>
                          with competent authorities, based on a good faith
                          belief that disclosure is necessary to respond to a
                          judicial process, a valid official inquiry, or if
                          otherwise required by law;
                        </p>
                      </li>
                      <li>
                        <p>
                          to defend our legal rights, or to protect the rights
                          or safety of any person or entity;
                        </p>
                      </li>
                      <li>
                        <p>as instructed by you, our customer or the user;</p>
                      </li>
                      <li>
                        <p>
                          with third parties in the context of reorganization of
                          operations
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <p class="font-size-16" style={{ color: "#3DCD58" }}>
                      <strong>How do we secure personal information?</strong>
                    </p>
                    <p>
                      We follow generally accepted industry standards to protect
                      personal information and we maintain appropriate
                      administrative, technical and physical safeguards to
                      protect it against accidental or unlawful destruction,
                      accidental loss and unauthorized alteration.{" "}
                    </p>
                    <p>
                      However, no method of transmission over the internet or of
                      electronic storage is 100% secure. Therefore, we cannot
                      guarantee absolute security.
                    </p>
                  </li>

                  <li>
                    <p class="font-size-16" style={{ color: "#3DCD58" }}>
                      <strong>
                        How to exercise your choices and rights about your
                        personal information?
                      </strong>
                    </p>
                    <p>
                      To exercise the data protection rights granted under
                      applicable data protection law, such as the rights to be
                      informed, to access, to rectify, to withdraw consent, to
                      request restriction or erasure, the right to object and
                      data portability, you may make a request at the contact
                      details available within the service or at
                      Global-Data-Privacy@schneider-electric.com. To ensure the
                      security of your personal information, we may ask you to
                      provide other details to verify your identity.
                    </p>
                    <p>
                      You may address questions or comments about our privacy
                      practices or this privacy notice to our Group Data
                      Protection Officer (Group DPO):
                    </p>
                    <p style={{ backgroundColor: "#ddd" }}>
                      DPO@schneider-electric.com
                      <br />
                      DPO Schneider Electric
                      <br />
                      35 rue Joseph Monier <br />
                      CS 30323
                      <br />
                      92506 Rueil Malmaison-
                      <br />
                      France
                    </p>
                    <p>
                      If you believe that we have processed your personal
                      information in violation of applicable law, you may file a
                      complaint with the Group DPO at the contact details above
                      or with a supervisory authority.
                    </p>
                    <p>
                      This privacy notice may change from time to time. We will
                      provide information about material changes. Last updated:
                      July 2021
                    </p>
                    <br />
                  </li>
                </ol>
              </div>
            </div>
          </se-container>

          <se-dialog-footer>
            <div className="row" style={{ width: "100%" }}>
              <hr />
              <div className="col">
                <se-checkbox
                  onClick={(e) => {
                    this.setState({ termsSubmit: !e.target.selected });
                  }}
                  selected="false"
                  label="I have read, understood and agree to the Terms and Conditions."
                ></se-checkbox>
              </div>
              <div className="col-2">
                <se-button
                  disabled={this.state.termsSubmit}
                  onClick={() => this.onClosePopUp("dialog-Popup")}
                >
                  Submit
                </se-button>
              </div>
            </div>
          </se-dialog-footer>
        </se-dialog>
      </se-container>
    );
  }
}

export default withRouter(ShellLayout);
