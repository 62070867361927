const API_CONFIG = {
  baseUrl: process.env.REACT_APP_BACKEND_API_URL,
  endpoints: {
    notificationData: "notifications",
    agroSiteList: "CustomerSites",
    agroCustomersList: "CustomersList",
    customerPersonalInfo: "CustomerInfo",
    userProfileInfo: "Users",
    //post method endpoints
    settingsWidget: "settingswidget",
    calculatedwidget: "calculatedwidget",
    notificationwidget: "NotificationsOverview",
    customerprofile: "CustomerInfo",
    agroDriveDetails: "CustomerDriveDetails",
    agroLandDetails: "CustomerLandDetails",
    agroDeviceDetails: "CustomerLoadDetails",
    agroIRRadianceDetails: "CustomerIRDetails",
    agroIncomeDetails: "CustomerIncomeDetails",
    notifications: "notifications",
    eventAcknowledge: "notifications",
    updateUserProfileInfo: "Users",
    //Admin Console Api
    adminConsole: "RawTelemetricData",
    //Details Page
    agroDashboardDetails: "DashboardWidgetDetails",
    donwloadPdfReport: "ReportGeneration",
    //reports Page
    getReportsTableData: "ReportGeneration",
    //Delete customer api
    deleteCustomer: "CustomerInfo",
    //login
    login: "login",
    //create user form
    createUser: "Users",

    //roles
    roles: "Roles",

    //generate raw data
    generateRawData: "RawTelemetricData",
    uploadGalleryPhoto: "GalleryPhoto",

    //admin panel
    customerDeviceList: "CustomerDevices",
    firmwareVersion: "ota",
    updateFirmwareSettingsForDevice: "Ota",

    customerLandInfo: "api/getCustomerLandInfo",
    customerGatewayInfo: "api/getCustomerGatewayInfo",
    customerPVDetails: "api/GetCustomerPVDetails",
    customerIncome: "api/getCustomerIncome",
    customerIrRadiance: "api/getCustomerIrRadiance",
    customerDeviceDetails: "api/AgroLoadDetails",
    agroPvDetails: "api/AgroPvDetails",
    //post RealTimeWidgetDetails api
    RealTimeWidgetDetails: "/api/RealTimeWidgetDetails",
    //table RealTimeWidgetDetails api
    RealTimeWidgetTableDetails: "/api/RealTimeWidgetDataTable",
    //loads list api
    agroAssets: "/api/AgroAssets",

    //CustomerDeviceConfigurations
    CustomerDeviceConfigurations: "CustomerDeviceConfigurations",
    UpdateMotorControlType: "UpdateMotorControlType",
    UpdateReferenceChannel: "UpdateReferenceChannel",
    UpdateProfile: "UpdateProfile",
    UpdateFrequency: "UpdateFrequency",
    StartStop: "StartStop",
    ConfigurationsReset: "Reset",
    GetData: "GetData",
    //Transaction Page
    getTransactionTableData: "GetTransaction",
  },
};
export default API_CONFIG;
