import React from 'react';
import AzureMapControls from '../../components/azureMapControls';
import useMycustomerForm from './useMycustomerForm';
import Auth from '../../api/ApiSession';
const _ = require('lodash');
const authCall = new Auth();
const LandDetails2 = (props) => {
    let mapKey = "Wh2PoXcr2n_lms3BUkbpH_0zynlRn4mvUUZ8O2_xPe0"
    const [infoData, setInfoData] = React.useState(props.infoData)
    const [locationData, setlocationData] = React.useState([])
    const [locationDataList, setlocationDataList] = React.useState([])
    const { errors } = useMycustomerForm(infoData, props.index)
    const onValueChange = (e, index) => {
        let { name, value } = e.target
        console.log(name, value);
        if (name === "Search Address") {
            authCall.getMapLocations(mapKey, value).then(json => {

                if (json.results) {
                    console.log(json.results)
                    setlocationDataList(json.results);
                }
                // console.log(locationDataList)
                let a = locationDataList.find(item => item.address.freeformAddress === value);
                if (a !== undefined) {
                    let latlog = []
                    latlog.push(a.position.lon)
                    latlog.push(a.position.lat)
                    updateMapLatLong(latlog);
                }
            })
                .catch(error => {
                    console.log(error);
                });
        }
        infoData[index].PropValue = value
        props.onBlur(infoData, props.index)
    }
    props.onChange(errors)
    const renderError = (data, index) => {
        if (errors[index].PropName === data.PropName && errors[index].PropMsg) {
            return <div className="se-error-text"> {errors[index].PropMsg}</div>
        }
    }
    const renderClass = (data, index) => {
        if (errors[index].PropName === data.PropName && errors[index].PropMsg) {
            return "se-form-control " + errors[index].PropClass
        } else {
            return "se-form-control"
        }
    }
    const updateMapLatLong = (latLong) => {
        let updatedValue
        setInfoData(latest_value => {
            updatedValue = latest_value
            return latest_value
        })
        fetch("https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=" + mapKey + "&query=" + latLong[1] + "," + latLong[0], {
            method: 'GET', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response
        }).then(res => res.json())
            .then(json => {
                console.log(json);
                _.map(updatedValue, ele => {
                    if (ele.PropName === "Latitude") { ele.PropValue = latLong[1].toFixed(6) }
                    if (ele.PropName === "Longitude") { ele.PropValue = latLong[0].toFixed(6) }
                    if(json.addresses[0]){
                        if (ele.PropName === "Address") { ele.PropValue = (json.addresses[0].address.municipalitySubdivision === undefined) ? "" : json.addresses[0].address.municipalitySubdivision }
                        if (ele.PropName === "City") { ele.PropValue = (json.addresses[0].address.countrySecondarySubdivision === undefined) ? "" : json.addresses[0].address.countrySecondarySubdivision }
                        if (ele.PropName === "State") { ele.PropValue = (json.addresses[0].address.countrySubdivision === undefined) ? "" : json.addresses[0].address.countrySubdivision }
                        if (ele.PropName === "Country") { ele.PropValue = (json.addresses[0].address.country === undefined) ? "" : json.addresses[0].address.country }
                        if (ele.PropName === "Pincode") { ele.PropValue = (json.addresses[0].address.postalCode === undefined) ? "" : json.addresses[0].address.postalCode }
                    }
                })
                props.onBlur(updatedValue, props.index)
                setInfoData(updatedValue)
                setlocationData(latLong)
            })
            
    }
    React.useEffect(() => {
        let a = props.infoData.findIndex(obj => obj.PropName === "Search Address")
        if (!props.isDisabled && a < 0 && props.index === 0) {
            props.infoData.splice(1, 0, { PropName: "Search Address", PropValue: "" })
        }
        setInfoData(props.infoData)
    }, [props.infoData])
    return <se-block display="grid">
        <div className="rows" align="">
            <div className="col-md-6 px-0">
                {_.map(infoData, (eachData, index) => {
                    return <div class="createBlockSize text-right pb-3">
                        <label className="col-sm-6 col-lg-6 col-xl-5 se-label height20">{eachData.PropName}
                            {eachData.PropName === "Annual Income (INR)" ? null : <span class="se-mandatory">*</span>}
                        </label>
                        <div className="col-md-6 px-0 text-left">
                            {
                                eachData.PropName === "Land Owned (In acres)" || eachData.PropName === "No. of Farmers Working" || eachData.PropName === "Annual Income (INR)" ?
                                    <input type="text" style={{ width: '100%' }} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                        disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                                    : eachData.PropName === "Search Address" ?
                                        <span><input type="text" list="locationDataList" style={{ width: '100%' }} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                            disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                                            <datalist id="locationDataList">
                                                {locationDataList.map((item, key) =>
                                                    <option key={key} value={item.address.freeformAddress} />
                                                )}
                                            </datalist></span>
                                        :
                                        // <input type="text" style={{ width: '100%' }} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                        //     disabled={true} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                                        <input type="text" style={{ width: '100%' }} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                        disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                            }

                            {renderError(eachData, index)}
                        </div>
                    </div>
                })}
            </div>
            {props.disabeldGraph ? null : <div className="col-md-6">
                <AzureMapControls isDisabled={props.isDisabled} locationData={locationData} mapKey={mapKey} onClick={updateMapLatLong} info={infoData} classStyle="azureMapControlsStyle2" />
            </div>}
        </div>
    </se-block>
}
export default LandDetails2