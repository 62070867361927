import React, { useState, useEffect } from "react";
import { NAVHEADERS, NAV2HEADERS, CONFIGURATIONS } from "./constants";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "../styles.css";
import DeviceSettings from "./deviceSettings";
import StartStop from "./startStop";
import Reset from "./reset";
import Transaction from "./transaction";
import Auth from "../../api/ApiSession";

const authCall = new Auth();

const NavigationBar = ({ handleClick, menuKey }) => {
  return (
    <nav style={{ height: "41px" }}>
      <p className="col-md-4 mt-0 mb-0 fs-4 text-left">
        {CONFIGURATIONS.Last_Refresh} :{" "}
        {moment().format("DD/MM/YYYY h:mm:ss A")}
      </p>
      <div className="sub-nav bg-white">
        {
          sessionStorage.getItem("UserType") === "Admin" ?
          NAVHEADERS.map(({ name, id }) => (
            <a
              className={menuKey === id ? "active" : ""}
              title={name}
              key={id}
              onClick={() => handleClick(id)}>
              {name}
            </a>
          )) :
          NAV2HEADERS.map(({ name, id }) => (
            <a
              className={menuKey === id ? "active" : ""}
              title={name}
              key={id}
              onClick={() => handleClick(id)}>
              {name}
            </a>
          ))
        }
      </div>
    </nav>
  );
};

const Configuration = () => {
  const [menuKey, setMenuKey] = useState(sessionStorage.getItem("UserType") === "Admin" ? 1 : 2);
  const [deviceData, setDeviceData] = useState({
    motorControlType: "",
    MobileNo: "",
    Frequency: "",
    Status: "",
    RemoteStartStop: "",
    DeviceId: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    authCall
      .getCustomerPersonalInfo(sessionStorage.getItem("customerId"))
      .then((res) => {
        if (res.detailsInfo[1] !== {}) {
          setDeviceData({
            ...deviceData,
            DeviceId: res.detailsInfo[1].infoData[0].PropValue,
          });
          fetchDeviceData(res.detailsInfo[1].infoData[0].PropValue);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const fetchDeviceData = (DeviceId) => {
    setLoading(false);

    authCall
      .getConfigurationData(CONFIGURATIONS.GetData, {
        DeviceId,
      })
      .then((response) => {
        setDeviceData({
          ...response[0],
          DeviceId,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleClick = (id) => {
    setMenuKey(id);
  };
  const menuItem = () => {
    switch (menuKey) {
      case 2:
        return (
          <StartStop deviceData={deviceData} setDeviceData={setDeviceData} />
        );

      case 3:
        return <Reset deviceData={deviceData} />;
        
      case 4:
        return <Transaction />;

      default:
        return (
          <DeviceSettings
            deviceData={deviceData}
            setDeviceData={setDeviceData}
          />
        );
    }
  };

  return (
    <div className="h-70 mx-4">
      <se-tabbar color="alternative">
        <NavigationBar handleClick={handleClick} menuKey={menuKey} />
      </se-tabbar>
      <se-divider class="mt-1"></se-divider>

      {loading ? (
        <se-loading loading={loading}></se-loading>
      ) : (
        <se-block direction="row">{menuItem()}</se-block>
      )}
    </div>
  );
};
export default Configuration;
