import React from 'react';
const _ = require('lodash');
const AssetsDetails = (props) => {
    // const deleteAssests = (index, LoadDataIndex) => {
    //     props.DeviceDetails[index].LoadData.splice(LoadDataIndex, 1)
    //     if (props.DeviceDetails[index].LoadData.length === 0) {
    //         props.DeviceDetails.splice(index, 1)
    //     }
    //     props.onBlur(props.DeviceDetails, props.index)
    // }
    const onCheckBoxClicked = (status,index,LoadDataIndex) => {
     props.DeviceDetails[index].LoadData[LoadDataIndex].PropStatus = status
     props.onBlur(props.DeviceDetails, props.index)
    }
    const onEdit = (data, assetsType) => {
        props.onEdit(true, data, assetsType)
    }
    const getScrollClass = (aType) => {
        let scrollCss = "pb-2"
        if (sessionStorage.getItem("dropdownCustomerType") === "SWP") {
            scrollCss = scrollCss + " table-scroll"
        }
        else if(aType==="Pump"){
            scrollCss = scrollCss + " table-vertical-scroll"
        }
        return scrollCss
    }
    return _.map(props.DeviceDetails, (DeviceDetails, index) => { 
        return <se-block class="" display="block" key={index}>
            <div>
                <span class="pl-3 font-normal text-left agroDeviceTitle">{DeviceDetails.AssetType}</span>
            </div>
            <se-block-content class={getScrollClass(DeviceDetails.AssetType)}>
                <div className={"rows blockDiv"} align="center">
                    <se-block value={"hello"} >
                        <div className="assestTable">
                            <table>
                                <tr>
                                    {_.map(DeviceDetails.loadHeader, (loadHeader, index) => {
                                        if (loadHeader.PropName !== "Enter Power Vs Water flow data") {
                                            return <th key={index}><strong>{loadHeader.PropName}</strong>
                                                {loadHeader.units ? <span className="font-size-12">({loadHeader.units})</span> : null}
                                            </th>
                                        }
                                    })}
                                    {props.isDisabled === false ? <th><strong>Action</strong></th> : null}
                                </tr>
                                {_.map(DeviceDetails.LoadData, (LoadData, LoadDataIndex) => {
                                    return <tr key={LoadDataIndex} className={LoadData.PropStatus ? "" : "disableTableRow"}>
                                        {_.map(LoadData.PropData, (PropData, dataIndex) => {
                                            if (PropData.PropName === "Type") {
                                                return <td key={dataIndex}>
                                                    {PropData.PropValue}
                                                </td>
                                            } else {
                                                if (PropData.PropName !== "Enter Power Vs Water flow data") {
                                                    return <td key={dataIndex}>
                                                        {PropData.PropValue}
                                                    </td>
                                                }
                                            }
                                        })}
                                        {props.isDisabled === false ? <td style={{ cursor: "pointer", position: "relative" }}>
                                            <div style={{ position: 'absolute', left: "3px", top: "5px" }}><se-icon class="" size="small" onClick={() => onEdit(LoadData, DeviceDetails.AssetType)} color="standard">action_editor</se-icon>
                                            </div>
                                            <div style={{ position: 'absolute', right: "0px", top: "5px" }}>
                                                {/* <se-icon class="pr-2" size="small" onClick={() => deleteAssests(index, LoadDataIndex)} color="standard">action_delete</se-icon> */}
                                                <se-checkbox onClick={(e) => onCheckBoxClicked(e.target.selected,index,LoadDataIndex)} selected={LoadData.PropStatus ? "true" : "false"}></se-checkbox>
                                            </div>
                                        </td> : null}
                                    </tr>
                                })}
                            </table>
                        </div>
                    </se-block>
                </div>
            </se-block-content>
        </se-block>
    })
}
export default AssetsDetails