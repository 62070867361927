import React from "react";
import { CONFIGURATIONS } from "./constants";

const styles = {
  dialogContentStyles: {
    lineHeight: "1.2",
  },
};

const Dialog = ({
  open,
  handleClose,
  handleConfirm,
  dialoghead,
  dialogContent,
}) => {
  return (
    <>
      <se-dialog id="dialog-simple" open={open} size="small">
        <div className="d-flex justify-content-end p-2">
          <se-icon option="button" onClick={handleClose}>
            action_close
          </se-icon>
        </div>
        <se-dialog-content>
          <div
            className="d-flex flex-column align-items-center pb-4"
            style={styles.dialogContentStyles}
          >
            <p>{dialoghead}</p>
            <p>{dialogContent}</p>
          </div>
        </se-dialog-content>
        <se-button option="primary" size="small" onClick={handleConfirm}>
          {CONFIGURATIONS.Confirm}
        </se-button>
      </se-dialog>
    </>
  );
};
export default Dialog;
