import React, { useRef, useState } from "react";
import _ from "lodash";
import CheckBox from "./checkBox";
import {
  CONFIGURATIONS,
  DIALOGCONTENT,
  RESETLABELS,
  RESETPARAMS,
} from "./constants";
import Dialog from "./dialog";
import PropTypes from "prop-types";
import Auth from "../../api/ApiSession";

const authCall = new Auth();

const Reset = ({ deviceData }) => {
  const [isSubmitValid, setIsSubmitValid] = useState(false);
  const [resetMotor, setResetMotor] = useState([]);
  const [dialogContent, setDialogContent] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackBarObj, setSnackBarObj] = React.useState({});

  const handleReset = (e) => {
    const name = e.target.name;
    const selected = e.target.selected;
    if (selected) {
      resetMotor.push(name);
    } else {
      _.pull(resetMotor, name);
    }
    setResetMotor(resetMotor);
    validateSubmit(resetMotor);
  };

  const validateSubmit = (resetMotor) => {
    if(resetMotor.length === 0) {
      setIsSubmitValid(false);
    } else {
      setIsSubmitValid(true);
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };
  const handleConfirm = () => {
    setOpen(false);
    let data = []; 
    resetMotor &&
      resetMotor.forEach((motor) => {
        if(motor==="DriveSoftRstrt")
        {
          data.push({userId: sessionStorage.getItem("userId"), cust_id:sessionStorage.getItem("customerId"), deviceId: deviceData.DeviceId,isProcessed:0,commandName: "DriveSoftRstrt",commandType: "DriveSoftRstrt",commandStatus:"Wait",commandValue: "True"});          
        }else{
          data.push({userId: sessionStorage.getItem("userId"), cust_id:sessionStorage.getItem("customerId"), deviceId: deviceData.DeviceId,isProcessed:0,commandName: "Reset",commandType: "Reset",commandStatus:"Wait",commandValue: motor});        
        }
      });

      authCall
      .postTransaction(data)
      .then((response) => {
        alert();
      })
      .catch((error) => {
        console.log(error);
      });
      resetMotor.length = 0;
      setResetMotor(resetMotor);
  };

  const alert = () => {
    document.getElementById('reset-snackbar').open = true;
    setSnackBarObj({ type: "success", msg: "Command Initiated successfully." });
  };

  return (
    <div
      className="d-flex justify-content-between flex-column h-100 mt-4"
      style={{ gap: "15rem" }}
    >
      <div>
        <CheckBox
          label={CONFIGURATIONS.Reset_Energy}
          handleReset={handleReset}
          name={RESETPARAMS["ENERGY"]}
          selected={resetMotor.includes(RESETPARAMS["ENERGY"])}
        />
        <CheckBox
          label={CONFIGURATIONS.Reset_Run_Time}
          handleReset={handleReset}
          name={RESETPARAMS["RUNTIME"]}
          selected={resetMotor.includes(RESETPARAMS["RUNTIME"])}
        />
        <CheckBox
          label={CONFIGURATIONS.DriveSoftRstrt}
          handleReset={handleReset}
          name={RESETPARAMS["DriveSoftRstrt"]}
          selected={resetMotor.includes(RESETPARAMS["DriveSoftRstrt"])}
        />
      </div>
      <div className="d-flex justify-content-end">
        <se-button
          disabled={isSubmitValid ? false : true}
          onClick={() => {
            let dialogContent = "Do you want to ";
            if (resetMotor.length > 2) {
              dialogContent += "Reset All ?";
            } else {
              dialogContent += `${DIALOGCONTENT[resetMotor[0]]}  ${
                DIALOGCONTENT[resetMotor[1]]
                  ? `& ${DIALOGCONTENT[resetMotor[1]]}`
                  : ""
              } ? `;
            }


            setDialogContent(dialogContent);
            setOpen(!open);
          }}
        >
          {CONFIGURATIONS.Submit}
        </se-button>
      </div>
      {open && (
        <Dialog
          open={open}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          dialoghead={CONFIGURATIONS.Dialog_Confirm}
          dialogContent={dialogContent}
        />
      )}
      <div class="paddingLeft300" align="left">
        <se-snackbar id="reset-snackbar" duration="5000" type={snackBarObj.type}
          message={snackBarObj.msg}>            
        </se-snackbar>
      </div>
    </div>
    
  );
};
export default Reset;

Reset.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  handleReset: PropTypes.func,
  selected: PropTypes.bool,
};
