import React, { useEffect, useRef, useState } from "react";
import { CONFIGURATIONS } from "./constants";
import Dialog from "./dialog";
import PropTypes from "prop-types";
import TableComponent from '../../components/tableComponent';
import Auth from "../../api/ApiSession";

const Transaction = () => {
    const [tableData, setTableData] = React.useState([])
    const [tableDataCopy, setTableDataCopy] = React.useState([])
    const [tableHeader, setTableHeader] = React.useState([])
    const authCall = new Auth();
    let counter = 0;

    const fetchData = () => {       
        authCall.getTransactionData(CONFIGURATIONS.GetTransaction,sessionStorage.getItem("customerId"),'All',sessionStorage.getItem("userId")).then(json => {
            if (json) {
                {/*
                const transactionList = [];
                json.forEach((t) => {
                    let val = "";
                    if(t.commandName === "Frequency"){
                        val = t.commandValue/10
                    }else if(t.commandName === "MotorNamePlate"){
                        val = "Basic MotorNamePlate"
                    }else if(t.commandName === "DryRun"){
                        val = "DryRun";
                    }  else{
                      val = t.commandValue;  
                    }
                    transactionList.push({commandName:t.commandName, commandValue: val, CreatedDate: t.CreatedDate, commandStatus: t.commandStatus });
                });
                setTableData(transactionList)*/
            }
                setTableData(json)
                setTableDataCopy(JSON.parse(JSON.stringify(json)))
            }
        })
    }

    React.useEffect(() => {
        setTableHeader(["Command", "CommandValue", "Created", "Status"]);
        fetchData()
        let intervalId = setInterval(() => {
            if (counter <= 2) {
                fetchData()
                counter++
            }
        }, 30000)
        // return unmount handler
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const search = (e) => {
        let searchValue = e.target.value
        searchValue = searchValue.toLowerCase();
        let searchResult = tableDataCopy.filter(ele => {
            console.log(ele);
            return (ele.commandName).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.commandValue).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.CreatedDate).toLowerCase().indexOf(searchValue) !== -1 ||
                (ele.commandStatus).toLowerCase().indexOf(searchValue) !== -1
        })
        setTableData(searchResult)
    }

    return (
        <se-container display="block" padding="large">
            <div class="col-md-12 px-0">
                <div class="row justify-content-center">
                <div class="col-md-10 col-lg-10 col-xl-8 px-0">

                <se-block display="block" color="standard">
                    <div class="col-md-12 pb-3 px-0 my-auto text-right">
                        <input class="mr-2" type="search1" placeholder="Search" onChange={(e) => search(e)}/>
                    </div>
                </se-block>

                <se-block display="block" class="px-0">
                    <TableComponent sortable={true} tableHeader={tableHeader} tableData={tableData} />
                </se-block>

                </div>
                </div>
            </div>
        </se-container>
    );
    
};
export default Transaction;